/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.province-select-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.province-select-container label {
  color: #1890ff;
  display: block;
  line-height: 1.4em;
}
.province-select-container > div.province-block {
  width: 175px;
  margin: 0 4px 8px 0;
}
.province-select-container > div.province-block.required label::before {
  content: "* ";
}
.desktop .province-select-container > div.province-block.postal-code-pane {
  flex-basis: 100%;
}
.desktop .province-select-container > div.province-block.postal-code-pane input {
  width: 175px;
}
