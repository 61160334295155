/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.form-row {
  display: flex;
}
.form-row > .ant-form-item {
  flex-grow: 1;
}
.form-row .form-row-space {
  flex-grow: 0;
  width: 24px;
}
.province-select-container::v-deep > div.province-block {
  flex-grow: 1;
  margin: 0 0 24px !important;
}
.province-select-container::v-deep > div.province-block:nth-child(odd) {
  margin-right: 24px !important;
}
.province-select-container::v-deep > .has-error {
  width: 100%;
  margin: -16px 0 24px;
}
