/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.command-pane {
  display: flex;
}
.command-pane .command-search-input {
  flex-grow: 1;
}
.command-pane button {
  margin-left: 4px;
}
.customer-list {
  min-height: 125px;
  padding-top: 24px;
}
.customer-list .ant-list-item {
  padding-top: 0;
  border-bottom: none !important;
}
